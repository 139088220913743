
@import '../node_modules/react-datasheet/lib/react-datasheet.css';

body {
  
  margin: 0;
  counter-reset: page;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
}

img.best-logo{
  height: 3rem;
}

.best-grid-container {
  display: block;
  padding: 5px;

  box-shadow: 0px 0px 6px #CCC; 
  margin: auto;
  width: 100%;
  margin-top: 20px;
  transition: box-shadow 0.5s ease-in;
}

table.data-grid {
  margin: auto;
  width: 100%;
}
.data-grid-container .data-grid .cell > input
{
  text-align:inherit;
  height: 1.5rem;
  border: 0;
  padding: inherit;
  font-size:inherit;
}
.data-grid-container .data-grid .cell {
  height: 1.5rem;
}