.print-only{
  display:none;
}

@media print{
  body{
    margin:0;
  }
  .print-only{
    display: unset;
  }
  .not-print, button{
    display:none!important;
  }

  .print-contaiter{
    display:block;
    //width:30%;
    padding: 1.5rem;
    table.data-grid{
      padding: 1.5rem;
      //width:100%;
      //display:block;
    }
  }
}
